// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config($font-family: '"Open Sans", sans-serif;');
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$livemap-app-web-primary: mat-palette($mat-indigo);
$livemap-app-web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$livemap-app-web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$livemap-app-web-theme: mat-light-theme((color: (primary: $livemap-app-web-primary,
      accent: $livemap-app-web-accent,
      warn: $livemap-app-web-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($livemap-app-web-theme);

@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/styles/customer-media.scss";
@import "assets/styles/custom-style.scss";
@import "assets/styles/font-awesome.min.css";
@import "assets/styles/style.scss";
@import "assets/styles/main.scss";

.red-snackbar {
  background: #8B0000;
  color: white;
}

.success-snackbar {
  background: #1f8dc6;
  color: white;
}

.success-snackbar-width {
  background: #1f8dc6;
  color: white;
  min-width: 175px !important;
}

.mat-tab-label {
  height: 30px !important;
  padding: 0 18px !important;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 100px !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}

mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #1f8dc6 !important;
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 20px !important;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.mat-ink-bar {
  // width: 125px !important;
  position: absolute;
  bottom: 0;
  height: 2px;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}

body .cdk-overlay-container {
  .mat-tooltip-panel {
    bottom: 100px;
    display: none;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000 !important;
} 

.modal-body {
  padding: 1rem 0 1rem 0 !important;
}

.modal-text {
  font-size: 13px;
  border: 1px solid #EBEFF2;
  background: #F8FBFC;
  border-radius: 4px;
  padding-bottom: 24px;
  padding-top: 24px;
  padding-right: 30%;
}

.loader.circle {
  height: 40px !important;
}


@media screen and (max-width: 1440px) {
  .stretchTabs .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .mat-tab-label {
    min-width: 50% !important;
  }
}
