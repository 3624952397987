@media (min-width: 300px) and (max-width: 600px) {

  body,
  html {
    display: table;
    width: 100%;
  }

  .mobile {
    overflow: scroll !important;
    overflow-x: hidden !important;
  }

  .mobArrow {
    left: 80% !important;
  }

  .subHeaderTabs img {
    padding-right: 0.5rem !important;
  }

  .subHeaderTabs div {
    margin-left: -9% !important;
  }

  .subMenu {
    margin-top: 5% !important;
  }

  .header img {
    left: 50px;
  }

  .subMenu ul.nav li:focus div {
    transform: rotateX(0deg);
  }

  body {}

  .subMenu ul.nav div {
    top: 61px !important;
  }

  .loginInnerDiv {
    padding: 0 !important;
  }

  .loginSignupPage img {
    width: 55% !important;
  }

  .subMenu ul.nav li a.top-a {
    /*margin-left: 33% !important;*/
  }

  .subMenu ul.nav li a.top-a {
    padding: 20px 0px 20px 15px !important;
  }

  .userImageMobile {
    height: 40px !important;
  }

  .gridBlock {
    margin: 0 auto 10vh;
  }

  .infoDetials {
    padding: 10px 0px !important;
  }

  .infoBlockSubHead {
    padding: 2px !important;
  }

  .infoBlock {
    width: 330px !important;
    max-width: 100% !important;
  }

  .map {
    text-align: left !important;
  }

  .container .paymentCards li {
    font-size: 12px;
  }

  .leftBlock1 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .rightBlock1 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .leftBlock2 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .rightBlock2 {
    width: 100% !important;
    max-width: 100% !important;
  }

  #canvas {
    width: 350px !important;
    height: 290px !important;
    max-width: 100% !important;
  }

  #chart-area2 {
    width: 124px !important;
    height: 67px !important;
    max-width: 100% !important;
  }

  #chart-area3 {
    width: 124px !important;
    height: 67px !important;
    max-width: 100% !important;
  }

  .subMenu {
    display: block !important;
  }

  #chart-area4 {
    width: 124px !important;
    height: 67px !important;
    max-width: 100% !important;
  }

  .loginSignupPage .facebookLogin {
    width: 100% !important;
  }

  .col-xs-6 {
    width: 36% !important;
  }

  .userName {
    font-size: 15px !important;
  }

  .userImageContent {
    padding: 10px !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .infomob {
    padding: 5px !important;
  }

  .infoBlockMob {
    width: 342px !important;
    max-width: 140% !important;
  }

  .userDetailContent {
    margin-left: 0% !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .tripsDiv::-webkit-scrollbar {
    width: 0% !important;
  }

  .loginButton {
    width: 12rem !important;
  }

  .subHeader {
    display: none;
  }

  .mobhead {
    position: absolute !important;
    top: 40% !important;
    left: 0 !important;
    transform: none !important;
    padding-left: 3% !important;
  }

  .mobsubhead {
    display: block !important;
    float: right !important;
    height: 40px !important;
    position: relative !important;
    top: 2.5rem !important;
    left: 0 !important;
    transform: none !important;
    padding-right: 3%;
  }

  .header {
    text-align: center !important;
    z-index: 9999999;
  }

  /*.loginSignupPage{*/
  /*height: 662px!important;*/
  /*max-height: 100% !important;*/
  /*}*/
  .infoBlock {
    width: 550px !important;
    max-width: 100% !important;
  }

  .emailHeader {
    margin-top: 3% !important;
    letter-spacing: 2px !important;
  }

  .forgotBacka {
    left: 3% !important;
    top: 7% !important;
  }

  .mobview {
    display: none !important;
  }

  .alert-danger {
    background-color: #f05050 !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    color: #ffffff !important;
    font-size: 11px !important;
    margin-top: -3% !important;
    padding: 0px !important;
  }

  .ngdialog.ngdialog-theme-default .ngdialog-content {
    width: 90% !important;
  }

}

/*Landscape view of mobiles*/
@media (min-width: 22rem) and (max-height: 33rem) {
  .subHeaderTabs {
    margin-left: 1% !important;
  }

  .subMenu ul.nav li a.top-a {
    margin-left: 30% !important;
  }

  /* .freeHidesHeader{
        padding: 4rem 7rem !important;
    } */
  .nav {
    /*margin-left: -20% !important;*/
  }

  .subHeaderTabs div {
    margin-left: -96% !important;
  }

  #canvas {
    width: 550px !important;
    height: 290px !important;
    max-width: 100% !important;
  }

  .emailHeader {
    margin-top: 3% !important;
    letter-spacing: 2px !important;
  }

}

@media (min-width: 600px) and (max-height: 34rem) {}

@media (min-width: 600px) and (max-width: 800px) {
  .userImageMobile {
    height: 141px !important;
  }

  .infoBlock {
    text-align: center !important;
  }

  .emailHeader {
    margin-top: 3% !important;
    letter-spacing: 2px !important;
  }

  .subMenu ul.nav {
    margin-left: 0%;
  }

  .subHeaderTabs div {
    margin-right: -4px;
  }

  body {
    overflow-x: hidden;
  }

  .subHeaderTabs {
    display: none !important;
  }

  .subHeader {
    display: none !important;
  }

  .subMenu {
    margin-top: 2% !important;
    display: block !important;
  }

  .header img {
    left: 13%;
  }

  .header {
    z-index: 999999;
  }

  .mobArrow {
    left: 77% !important;
  }

  .mobimg {
    margin-left: 10% !important;
  }

  .header {
    background-color: #37474f;
  }
}

@media (min-width: 22rem) and (max-height: 33rem) {
  html body {
    width: 100%;
    height: 100%;
    display: table;
  }

  .gridBlock {
    margin: 0 auto 5rem;
  }

  .subMenu ul.nav {
    margin-left: -60%;
  }

  .subMenu ul.nav div.sub {
    margin-left: 15%;
  }
}
